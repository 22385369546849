.color-grey {
    color: #373A40!important;
}

.color-primary-blue {
    color: #70E9F1!important;
}

.cursorOnHover {
    cursor: pointer;
}

* {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #686D76;
}

@media (prefers-reduced-motion: no-preference) {}


/* font-size: calc(10px + 2vmin); */

.loader {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: #70e9f1;
    letter-spacing: 0.2em;
}

.loader::before,
.loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #70e9f1;
    position: absolute;
    animation: load .7s infinite alternate ease-in-out;
}

.loader::before {
    top: 0;
}

.loader::after {
    bottom: 0;
}

@keyframes load {
    0% {
        left: 0;
        height: 30px;
        width: 15px
    }
    50% {
        height: 8px;
        width: 40px
    }
    100% {
        left: 235px;
        height: 30px;
        width: 15px
    }
}


/* .loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #70e9f1;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
 */
